import React, {createContext, useContext, useEffect} from 'react';
import {useMutation, useQuery} from '@apollo/react-hooks';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserRights } from '../../../common/hooks/useUserRights';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { IRadioItem } from '../../../../../libs/uiKit/radio/types';
import { DOCS, GROUPS, STATUSES } from '../../../../../libs/api/queries';
import {
	AnnulAgreementMutation, AnnulAgreementMutationVariables,
	DeleteAgreementMutation, DeleteAgreementMutationVariables,
	DictKeyEnum,
	DocsQuery,
	DocsQueryVariables,
	DocTypeEnum,
	GroupsQuery,
	GroupsQueryVariables,
	StatusesQuery,
	StatusesQueryVariables,
} from '../../../../../libs/api/graphqlTypes';
import { HeadType, RowType } from '../../../../../libs/uiKit/table/types';
import { IDropdownItem } from '../../../../../libs/uiKit/dropdownMenu/types';
import { PencilIcon } from '../../../../../icons/ui/Pencil';
import Button from '../../../../../libs/uiKit/button';
import { TrashIcon } from '../../../../../icons/ui/Trash';
import {stringToMoney, stringToNumberDigits} from '../../../../../libs/uiKit/utils';
import { IForm } from '../../../../../libs/uiKit/fieldsBuilder/types';
import useGetDicti from '../../../common/hooks/useGetDicti';
import { IEnumItem } from '../../../../../libs/uiKit/enums/types';
import { ISelectItem } from '../../../../../libs/uiKit/select/types';
import Status from '../../../../../libs/uiKit/status';
import { Text4 } from '../../../../../libs/uiKit/globalStyledComponents';
import { StyledGroupPath } from '../styles';
import useNotification from '../../../../widgets/notifier/ui/hooks/useNitification';
import { DRAFT_STATUS_VALUE } from '../../../../../libs/utils/staticData';
import { AgreementSteps } from '../../../createAgreement/ds/repositories/commands';
import DropdownMenu from '../../../../../libs/uiKit/dropdownMenu';
import { DEFAULT_FILTER_VALUE, DEFAULT_PAGINATION_VALUE, getShortName, IFilterState, isFilterCheck, SearchUrlService } from '../../ds/commands';
import ConfirmPopup from "../../../../widgets/confirmPopup";
import {ANNUL_AGREEMENT, DELETE_AGREEMENT} from "../../../../../libs/api/commands";
import {SelectArrowIcon} from "../../../../../libs/uiKit/icons/SelectArrow";
import {ArrowIcon} from "../../../../../icons/ui/Arrow";
import {checkUiVersion} from "../../../../../libs/utils/checkUiVersion";

type AgreementsLogContext = {
	isShowAgreements: boolean;
	isShowContracts: boolean;
	isShowProlongation: boolean;
	isOpenContinueContractHandler: boolean;
	openContinueContractHandler: () => void;
	closeContinueContractHandler: () => void;
	isCanCreateAgreement: boolean;
	dropDownItems: IDropdownItem[];
	head: HeadType;
	rows: RowType[];
	isLoading: boolean;
	// pagination
	total: number;
	selectedPage: number;
	changePaginationHandler: (page: number) => void;
	// filters
	onFormSubmit: () => void;
	form: IForm;
	resetFiltersHandler: () => void;
	isHaveFilters: boolean;
	changeSearchHandler: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	searchText: string;
	accodionHeader: string;
	isOpenAccordion: boolean;
	switchAccordionHandler: (isOpen: boolean) => void;
	// precalc
	isOpenSkipPrecalculation: boolean;
	closeSkipPrecalculationonHandler: () => void;
	skipPrecalculationCallback: (id: string) => void;
	banksOk: boolean;
};

export type AgreementLogType = 'AGREEMENT' | 'CALCULATION' | 'PROLONGATION';
type PathType = 'draft' | 'seller' | 'underwriting' | 'contract';

export type AgreementsLogProps = {
	type: AgreementLogType;
	children: React.ReactNode;
};

const AgreementsLogContext = createContext<AgreementsLogContext>({} as AgreementsLogContext);
export const useAgreementsLogContext = (): AgreementsLogContext => useContext(AgreementsLogContext);

const ROW_PER_PAGE = 10;
const RADIO_DATE_INTERVAL: IRadioItem[] = [
	{ label: 'не выбрано', value: 'all' },
	{ label: 'за месяц', value: 'month' },
	{ label: 'за полгода', value: 'halfYear' },
	{ label: 'за год', value: 'year' },
	{ label: 'выбрать даты', value: 'select' },
];

const AgreementsLogContextProvider: React.FC<AgreementsLogProps> = props => {
	const { type, children } = props;
	const {
		user: { isSuper, banksOk, id: currentUserId },
		routes: { agreementByIdSeller, agreementByIdUnderwriter, contractById, error500, precalculation, createAgreementStep },
	} = useGlobalContext();
	const { setNotification } = useNotification();
	const { rightsHandler } = useUserRights();
	const navigate = useNavigate();
	const location = useLocation();
	const { data: selectBanks, loading: loadingBanks } = useGetDicti(DictKeyEnum.CreditBank);
	const formHook = useForm({ mode: 'onBlur' });

	const selectFillingTypes = [
		{label: "По документу", value: "upload"},
		{label: "С анкетой", value: "online"},
		{label: "По декларации", value: "declaration"},
	];

	const [stateContinueContractModal, setContinueContractModal] = React.useState<boolean>(false);
	const [stateSkipPrecalculationModal, setSkipPrecalculationModal] = React.useState<boolean>(false);
	const [stateIsOpenAccordion, setIsOpenAccordion] = React.useState<boolean>(false);
	const [stateDeleteConfirmModal, setDeleteConfirmModal] = React.useState<boolean>(false);
	const [stateAnnulConfirmModal, setAnnulConfirmModal] = React.useState<boolean>(false);
	const [stateDeleteId, setDeleteId] = React.useState<number>(0);
	const [stateAnnulId, setAnnulId] = React.useState<number>(0);


	const { search } = location;
	const [filters = DEFAULT_FILTER_VALUE, pagination = DEFAULT_PAGINATION_VALUE] = SearchUrlService.fromUrl(search);

	const [stateDateBegin, setDateBegin] = React.useState<Date|null>(filters.dateStart ? dayjs(filters.dateStart, 'DD.MM.YYYY').toDate() : null);
	const [stateDateEnd, setDateEnd] = React.useState<Date|null>(filters.dateEnd ? dayjs(filters.dateEnd, 'DD.MM.YYYY').toDate() : null);

	console.log(filters, stateDateBegin, stateDateEnd);

	const { data: dataGroups, loading: loadingGroups } = useQuery<GroupsQuery, GroupsQueryVariables>(GROUPS, { onError: error => error500(error) });
	const { data: dataStatuses, loading: loadingStatuses } = useQuery<StatusesQuery, StatusesQueryVariables>(STATUSES);
	const { data: dataDocs, loading: loadingDocs } = useQuery<DocsQuery, DocsQueryVariables>(DOCS, {
		variables: {
			type: type as DocTypeEnum,
			limit: ROW_PER_PAGE,
			page: pagination.activePage,
			filter: {
				text: filters.text,
				banks: filters.banks,
				status: filters.statuses,
				groups: filters.groups,
				fillingTypes: filters.fillingTypes,
				period: {
					start: filters.dateStart,
					end: filters.dateEnd,
				},
			},
		},
		fetchPolicy: "no-cache",
		onError: error => setNotification({ type: 'error', text: error }),
	});

	const [deleteAgreement, {loading: loadingDeleteAgreement}] = useMutation<DeleteAgreementMutation, DeleteAgreementMutationVariables>(DELETE_AGREEMENT, {
		refetchQueries: ['docs'],
		awaitRefetchQueries: true,
	});

	const [annulAgreement, {loading: loadingAnnulAgreement}] = useMutation<AnnulAgreementMutation, AnnulAgreementMutationVariables>(ANNUL_AGREEMENT, {
		refetchQueries: ['docs'],
		awaitRefetchQueries: true,
	});

	useEffect(() => {
		checkUiVersion();
	}, []);

	React.useEffect(() => {
		if (!search) return navigate(`${location.pathname}${SearchUrlService.toUrl(DEFAULT_FILTER_VALUE, DEFAULT_PAGINATION_VALUE)}`);
	}, [search, navigate, location.pathname]);

	const isSeller: boolean = rightsHandler('applicationPage');
	const isUnderwriter: boolean = rightsHandler('underwritingPage');
	const sellerAndUnderwriter = (isSeller && isUnderwriter) || isSuper;
	const total: number = dataDocs?.docs?.total || 0;
	const selectGroups: ISelectItem[] = (dataGroups?.groups || []).map(group => ({ label: group?.name || 'unknown', value: String(group?.id) }));

	const goToView = ({ id, step, goTo, isn }: { id: number; step: AgreementSteps; goTo?: PathType, isn?: string | null }): void => {
		switch (goTo) {
			case 'draft':
				return navigate(createAgreementStep(String(id), step));
			case 'seller':
				return navigate(agreementByIdSeller(isn || String(id)));
			case 'underwriting':
				return navigate(agreementByIdUnderwriter(isn || String(id)));
			case 'contract':
				return navigate(contractById(isn || String(id)));
			default:
				return setNotification({ type: 'error', text: 'redirect path not found' });
		}
	};

	const deleteDocHandler = (id: number): void => {
		setDeleteId(id);
		setDeleteConfirmModal(true);
	};

	const annulDocHandler = (id: number): void => {
		setAnnulId(id);
		setAnnulConfirmModal(true);
	};

	const deleteDocConfirmed = (): void => {
		setDeleteConfirmModal(false);
		deleteAgreement({
			variables: {
				id: Number(stateDeleteId),
			}
		}).then(() => {
			setNotification({ type: 'success', text: 'Документ успешно удален' });
		}).catch(error => {
			setNotification({ type: 'error', text: error });
		});
	}

	const annulDocConfirmed = (): void => {
		setAnnulConfirmModal(false);
		annulAgreement({
			variables: {
				id: Number(stateAnnulId),
			}
		}).then(() => {
			setNotification({ type: 'success', text: 'Договор аннулирован' });
		}).catch(error => {
			setNotification({ type: 'error', text: error });
		});
	}

	const [currentInterval] = formHook.watch(['interval']);

	const isCustomDates = currentInterval === 'select';

	useEffect(() => {
		if(currentInterval && !isCustomDates){
			let period = 1;
			if(currentInterval === 'quarter'){
				period = 3;
			}
			if(currentInterval === 'halfYear'){
				period = 6;
			}
			if(currentInterval === 'year'){
				period = 12;
			}
			if(currentInterval === 'all'){
				period = 0;
			}
			if(period > 0){
				setDateBegin(dayjs().subtract(period, 'month').toDate());
				setDateEnd(dayjs().toDate());
			} else {
				setDateBegin(null);
				setDateEnd(null);
			}

		}
	}, [currentInterval, isCustomDates]);

	const statuses: IEnumItem[] = React.useMemo(
		() => (dataStatuses?.statuses || []).map(status => ({ label: status?.label || 'unknown', value: status?.value || 'unknown' })),
		[dataStatuses?.statuses]
	);
	const enumSelectedStatuses: IEnumItem[] = React.useMemo(() => statuses.filter(status => filters.statuses.includes(String(status.value))), [filters.statuses, statuses]);
	const enumSelectedBanks: IEnumItem[] = React.useMemo(() => selectBanks.filter(bank => filters.banks.includes(String(bank.value))), [filters.banks, selectBanks]);
	const enumSelectedGroups: IEnumItem[] = React.useMemo(() => selectGroups.filter(group => filters.groups.includes(String(group.value))), [filters.groups, selectGroups]);
	const enumFillingTypes: IEnumItem[] = React.useMemo(() => selectFillingTypes.filter(ft => filters.fillingTypes.includes(String(ft.value))), [filters.fillingTypes]);

	const selectedIntervalRadio: IRadioItem | undefined = RADIO_DATE_INTERVAL.find(interval => interval.value === filters.interval);
	const accodionHeaderText: string = `Интервал: ${selectedIntervalRadio?.label}, банки: ${
		enumSelectedBanks.length ? enumSelectedBanks.reduce((prev, bank) => `${prev}${bank.label}, `, '') : 'все,'
	} статусы: ${enumSelectedStatuses.length ? enumSelectedStatuses.reduce((prev, status) => `${prev}${status.label}, `, '') : 'все,'} группы: ${
		enumSelectedGroups.length ? enumSelectedGroups.reduce((prev, group) => `${prev}${group.label}, `, '') : 'все, '} типы заполнения: ${
		enumFillingTypes.length ? enumFillingTypes.map(group => group.label).join(", ") : 'все'}`;

	const switchAccordionHandler = React.useCallback((isOpen: boolean) => setIsOpenAccordion(isOpen), []);
	const changeFiltersHandler = React.useCallback((currentFilters: IFilterState): void => {
		const url = SearchUrlService.toUrl(currentFilters, pagination);
		navigate(url);
	}, [navigate, pagination]);
	const changePaginationHandler = React.useCallback((page: number): void => navigate(SearchUrlService.toUrl(filters, { activePage: page })), [filters, navigate]);
	const resetFiltersHandler = React.useCallback((): void => {
		navigate(SearchUrlService.toUrl(DEFAULT_FILTER_VALUE, DEFAULT_PAGINATION_VALUE));
		switchAccordionHandler(false);
	}, [navigate, switchAccordionHandler]);

	const changeSearchHandler = React.useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>): void => changeFiltersHandler({ ...filters, text: ev.target.value }),
		[changeFiltersHandler, filters]
	);

	useEffect(() => {
		if(total > 0 && total <= (pagination.activePage - 1) * ROW_PER_PAGE){
			changePaginationHandler(Math.ceil(total / ROW_PER_PAGE));
		}
	}, [total]);

	const onFormSubmit = formHook.handleSubmit(data => {
		changeFiltersHandler({
			...filters,
			interval: data.interval,
			dateStart: data.interval === 'all' ? null: (dayjs(stateDateBegin).format('DD.MM.YYYY') || filters.dateStart),
			dateEnd: data.interval === 'all' ? null : (dayjs(stateDateEnd).format('DD.MM.YYYY') || filters.dateEnd),
			statuses: (data.statuses || []).map((status: IEnumItem) => status.value),
			banks: (data.banks || []).map((bank: IEnumItem) => bank.value),
			groups: (data.groups || []).map((group: IEnumItem) => group.value),
			fillingTypes: (data.fillingTypes || []).map((fillingType: IEnumItem) => fillingType.value),
		});
		switchAccordionHandler(false);
	});


	const form: IForm = React.useMemo(
		() => ({
			formHook,
			hotReload: true,
			fields: [
				{
					field: {
						template: isCustomDates ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr 3fr',
						fieldType: 'radio',
						grid: isCustomDates ? 6 : 12,
						fieldName: 'interval',
						items: RADIO_DATE_INTERVAL,
						defaultValue: filters.interval,
					},
				},
				{
					isHidden: !isCustomDates,
					field: {
						fieldType: 'date',
						fieldName: 'dateStart',
						isRequired: true,
						grid: 3,
						errorMessage: 'Выберите дату',
						placeholder: 'Выберите дату',
						defaultValue: stateDateBegin,
						onChange: (date: Date | null): void => setDateBegin(date),
					},
				},
				{
					isHidden: !isCustomDates,
					field: {
						fieldType: 'date',
						fieldName: 'dateEnd',
						isRequired: true,
						grid: 3,
						errorMessage: 'Выберите дату',
						placeholder: 'Выберите дату',
						defaultValue: stateDateEnd,
						onChange: (date: Date | null): void => setDateEnd(date),
					},
				},
				{
					field: {
						fieldType: 'enums',
						fieldName: 'statuses',
						height: 15,
						grid: 3,
						placeholder: 'Выберите статусы',
						items: statuses,
						isLoading: loadingStatuses,
						defaultValue: enumSelectedStatuses.length ? enumSelectedStatuses : undefined,
					},
				},
				{
					isHidden: type === 'PROLONGATION',
					field: {
						fieldType: 'enums',
						fieldName: 'banks',
						height: 15,
						grid: 3,
						placeholder: 'Выберите банки',
						items: selectBanks,
						isLoading: loadingBanks,
						defaultValue: enumSelectedBanks.length ? enumSelectedBanks : undefined,
					},
				},
				{
					field: {
						fieldType: 'enums',
						fieldName: 'groups',
						height: 15,
						grid: 3,
						placeholder: 'Выберите группы',
						items: selectGroups,
						isLoading: loadingGroups,
						defaultValue: enumSelectedGroups.length ? enumSelectedGroups : undefined,
					},
				},
				{
					field: {
						fieldType: 'enums',
						fieldName: 'fillingTypes',
						height: 15,
						grid: 3,
						placeholder: 'Выберите способ оформления',
						items: selectFillingTypes,
						isLoading: false,
						defaultValue: enumSelectedGroups.length ? enumSelectedGroups : undefined,
					},
				},

			],
		}),
		[
			currentInterval,
			enumSelectedBanks,
			enumSelectedGroups,
			enumSelectedStatuses,
			filters.interval,
			filters.dateStart,
			filters.dateEnd,
			formHook,
			loadingBanks,
			loadingGroups,
			loadingStatuses,
			selectBanks,
			selectGroups,
			statuses,
			type,
		]
	);

	const head: HeadType = React.useMemo(
		() => ({
			cells: [
				{
					key: 'view',
					content: 'просмотр',
					width: 5,
				},
				{
					key: 'number',
					content: type === 'CALCULATION' ? '№ заявки' : '№ договора',
				},
				{
					key: 'summ',
					content: 'Сумма кредита, RUB',
				},
				{
					key: 'bank',
					content: 'Банк',
				},
				{
					key: 'added',
					content: 'Дата создания',
				},
				{
					key: 'insurer',
					content: 'Заемщик',
				},
				{
					key: 'fillingType',
					content: 'Тип заполнения',
				},
				{
					key: 'status',
					content: 'Статус',
				},
				{
					key: 'created',
					content: 'Создана',
				},
				{
					key: 'group',
					content: 'Группа',
				},
				...(sellerAndUnderwriter
					? [
							{
								key: 'empty',
								content: '',
								width: 2.5,
							},
					  ]
					: []),
			],
		}),
		[sellerAndUnderwriter, type]
	);

	const rows: RowType[] = (dataDocs?.docs?.data || []).reduce<RowType[]>((prev, doc) => {
		if (!doc) return prev;
		const { id, number, creditSumm, bankName, added, insurerName, user, groupPath, status, calcForm, fillingType, isn } = doc;
		const isDraft: boolean = status?.value === DRAFT_STATUS_VALUE;
		const isAnnul: boolean = status?.value === 'annul';
		const isShowDropDown: boolean = sellerAndUnderwriter && type === 'CALCULATION' && !isDraft;
		const isAgreement: boolean = (type === 'AGREEMENT' || type === 'PROLONGATION');
		let path: PathType | undefined;

		switch (type) {
			case 'CALCULATION':
				if (isDraft) {
					path = 'draft';
					break;
				}
				if (isUnderwriter) {
					path = 'underwriting';
					break;
				}
				if (isSeller) {
					path = 'seller';
					break;
				}
				break;
			case 'AGREEMENT':
			case 'PROLONGATION':
				path = 'contract';
				break;
			default:
				break;
		}

		return [
			...prev,
			{
				key: String(id),
				cells: [
					{
						key: 'cell-view',
						content: (
							<>
								{isShowDropDown && (
									<DropdownMenu
										direction='right'
										items={[
											{
												label: 'Андеррайтинг',
												onClick: () => goToView({ id, step: (calcForm?.stage as AgreementSteps) || 'contragentStep', goTo: 'underwriting', isn }),
											},
											{ label: 'Оформление', onClick: () => goToView({ id, step: (calcForm?.stage as AgreementSteps) || 'contragentStep', goTo: 'seller', isn }) },
										]}
										trigger={<Button appearance='flag' icon={<PencilIcon />} />}
									/>
								)}
								{!isShowDropDown && (
									<Button
										onClick={(): void =>
											goToView({
												id,
												step: (calcForm?.stage as AgreementSteps) || 'contragentStep',
												goTo: path,
												isn,
											})
										}
										appearance='flag'
										icon={<PencilIcon />}
									/>
								)}
							</>
						),
					},
					{ key: `cell-${id}-number`, content: number },
					{ key: `cell-${id}-summ`, content: stringToMoney(creditSumm) },
					{ key: `cell-${id}-bank`, content: bankName },
					{ key: `cell-${id}-added`, content: added },
					{ key: `cell-${id}-insurer`, content: insurerName },
					{ key: `cell-${id}-fillingType`, content: fillingType },
					{
						key: `cell-${id}-status`,
						content: type === 'AGREEMENT' && (user?.id === currentUserId || isSuper) && !['annul', 'clientRefusal'].includes(status?.value || "") ?
						 	<DropdownMenu
						 		trigger={
									<Status status={status?.label} color={status?.color} iconAfter={<ArrowIcon direction='down' />} />
						 		}
						 		items={[
									{ label: 'Аннулирован', onClick: () => annulDocHandler(id)},
								]}
						 	/>
							:
							<Status status={status?.label} color={status?.color} />
					},
					{ key: `cell-${id}-created`, content: `${getShortName(user?.full_name || '')}` },
					{
						key: `cell-${id}-group`,
						content: (
							<StyledGroupPath>
								{(groupPath || []).map((group, index) => (
									<Text4 key={`group-${index}-${id}`}>{group}</Text4>
								))}
							</StyledGroupPath>
						),
					},
					...(isDraft || (isAgreement && !isAnnul)
						? [
								{
									key: 'cell-row-empty',
									content: <Button onClick={(event): void => {
										event.stopPropagation();
										if(isAgreement){
											return annulDocHandler(id);
										}
										deleteDocHandler(id)
									}} appearance='flag' isLoading={loadingDeleteAgreement || loadingAnnulAgreement} icon={<TrashIcon />} />,
								},
						  ]
						: []),
				],
				onClick: (): void => goToView({
					id,
					step: (calcForm?.stage as AgreementSteps) || 'contragentStep',
					goTo: path,
					isn,
				}),
			},
		];
	}, []);

	const dropDownItems: IDropdownItem[] = React.useMemo(
		() => [
			{
				label: 'С предварительным расчетом',
				onClick: () => navigate(precalculation()),
			},
			{
				label: 'Без предварительного расчета',
				onClick: () => setSkipPrecalculationModal(true),
			},
		],
		[navigate, precalculation]
	);

	const value: AgreementsLogContext = React.useMemo(
		() => ({
			isShowAgreements: type === 'CALCULATION',
			isShowContracts: type === 'AGREEMENT',
			isShowProlongation: type === 'PROLONGATION',
			isOpenContinueContractHandler: stateContinueContractModal,
			openContinueContractHandler: () => setContinueContractModal(true),
			closeContinueContractHandler: () => setContinueContractModal(false),
			isCanCreateAgreement: rightsHandler('precalculationPage') && banksOk,
			dropDownItems,
			head,
			rows,
			isLoading: loadingDocs,
			// pagination
			total,
			selectedPage: pagination.activePage - 1,
			changePaginationHandler,
			// filters
			isOpenAccordion: stateIsOpenAccordion,
			switchAccordionHandler,
			isHaveFilters: isFilterCheck(filters),
			resetFiltersHandler,
			changeSearchHandler,
			searchText: filters.text || '',
			onFormSubmit,
			form,
			accodionHeader: accodionHeaderText,
			isOpenSkipPrecalculation: stateSkipPrecalculationModal,
			closeSkipPrecalculationonHandler: () => setSkipPrecalculationModal(false),
			skipPrecalculationCallback: id => navigate(createAgreementStep(id, 'contragentStep')),
			banksOk,
		}),
		[
			type,
			stateContinueContractModal,
			rightsHandler,
			dropDownItems,
			head,
			rows,
			loadingDocs,
			total,
			pagination.activePage,
			changePaginationHandler,
			stateIsOpenAccordion,
			switchAccordionHandler,
			filters,
			resetFiltersHandler,
			changeSearchHandler,
			onFormSubmit,
			form,
			accodionHeaderText,
			stateSkipPrecalculationModal,
			navigate,
			createAgreementStep,
		]
	);

	return <>
		<AgreementsLogContext.Provider value={value}>{children}</AgreementsLogContext.Provider>
		<ConfirmPopup
			isOpen={stateDeleteConfirmModal}
			onClose={() => setDeleteConfirmModal(false)}
			text="Вы действительно хотите удалить заявку?"
			confirmCallback={deleteDocConfirmed}
		/>
		<ConfirmPopup
			isOpen={stateAnnulConfirmModal}
			onClose={() => setAnnulConfirmModal(false)}
			text="Вы действительно хотите аннулировать договор?"
			confirmCallback={annulDocConfirmed}
		/>
	</>;
};

export default React.memo(AgreementsLogContextProvider);
