import React, { createContext, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { ISelectItem } from '../../../../../../../libs/uiKit/select/types';
import { IDaDataValue } from '../../../../../../../libs/uiKit/daData/types';
import useGetDicti from '../../../../../common/hooks/useGetDicti';
import { DictKeyEnum } from '../../../../../../../libs/api/graphqlTypes';
import Button from '../../../../../../../libs/uiKit/button';
import { RADIO_BOOLEAN } from '../../../../../../../libs/utils/staticData';
import {useCreateAgreementContext} from "../../../../ui/context";

type PropertyContext = {
	form: IForm;
};

export type PropertyContextProps = {
	children?: React.ReactNode;
	isFull: boolean;
	number: number;
	defaultValues?: PropertyField;
	arrayName: string;
	propertiesCount?: number;
};

export type PropertyField = {
	pledge?: ISelectItem | null;
	pledgeAddress?: IDaDataValue | null;
	pledgeAddressEgrn?: string;
	pledgeShare?: number;
	agency?: string;
	subjectCost?: number;
	nameOfAppraiser?: string;
	subjectCostByContract?: number;
	area?: number;
	roomsCount?: number;
	objectOfUnfinishedConstruction?: string;
	repairPlanning?: string;
	repairIntervalStart?: Date | null;
	repairIntervalEnd?: Date | null;
	riskCoefficient?: number;
	basementOrAttic?: string;
	floor?: number;
	floorCount?: number;
	yearOfBuild?: string;
	yearOfCapitalRepair?: string;
	wallMaterial?: ISelectItem | null;
	floorMaterial?: ISelectItem | null;
	interiorDecoration?: string;
	replanPlanning?: string;
	replanIntervalStart?: Date | null;
	replanIntervalEnd?: Date | null;
	rent?: string;
	damage?: string;
	signaling?: string;
	signalingInput?: string;
	openFire?: string;
	openFireInput?: string;
	additionalRisks?: string;
	additionalRisksInput?: string;
	cadastralNumber?: string;
};

const PropertyContext = createContext<PropertyContext>({} as PropertyContext);

export const usePropertyContext = (): PropertyContext => useContext(PropertyContext);

const FLAT_ISN: string = '654391';
const FLOOR_REQUIRED: string[] = [FLAT_ISN, '1867861', '833631'];
const FLOOR_COUNT_HIDDEN: string[] = ['654411'];
const ROOM_REQUIRED: string[] = [FLAT_ISN, '1867861'];

const PropertyContextProvider: React.FC<PropertyContextProps> = props => {
	const { children, defaultValues, number, isFull, arrayName, propertiesCount } = props;
	const formHook = useFormContext();
	const { data: selectPledge, loading: loadingPledge } = useGetDicti(DictKeyEnum.PropertyType);
	const { data: selectWallMaterial, loading: loadingWallMaterial } = useGetDicti(DictKeyEnum.WallMaterial);

	const {
		isFieldsDisabled
	} = useCreateAgreementContext();

	const [stateAddressEgrn, setAddressEgrn] = React.useState<boolean>(false);

	const [repairPlanningValue, replanPlanningValue, pledgeValue, signalingValue, openFireValue, additionalRisksValue, floorCountValue] = formHook.watch([
		`${arrayName}[${number}].repairPlanning`,
		`${arrayName}[${number}].replanPlanning`,
		`${arrayName}[${number}].pledge`,
		`${arrayName}[${number}].signaling`,
		`${arrayName}[${number}].openFire`,
		`${arrayName}[${number}].additionalRisks`,
		`${arrayName}[${number}].floorCount`,
	]);
	const isPlanningRepair: boolean = repairPlanningValue === 'true';
	const isPlanningReplan: boolean = replanPlanningValue === 'true';
	const isSignaling: boolean = signalingValue === 'true';
	const isOpenFire: boolean = openFireValue === 'true';
	const isAdditionalRisks: boolean = additionalRisksValue === 'true';
	const pledgeIsn: string | undefined = pledgeValue?.value;
	const isFlat: boolean = FLAT_ISN === pledgeIsn;

	const {
		pledge,
		pledgeAddress,
		pledgeAddressEgrn,
		pledgeShare,
		agency,
		subjectCost,
		nameOfAppraiser,
		subjectCostByContract,
		area,
		roomsCount,
		objectOfUnfinishedConstruction,
		repairPlanning,
		repairIntervalStart,
		repairIntervalEnd,
		replanIntervalStart,
		replanIntervalEnd,
		riskCoefficient,
		basementOrAttic,
		floor,
		floorCount,
		yearOfBuild,
		yearOfCapitalRepair,
		wallMaterial,
		floorMaterial,
		interiorDecoration,
		replanPlanning,
		rent,
		damage,
		signaling,
		signalingInput,
		openFire,
		openFireInput,
		additionalRisks,
		additionalRisksInput,
		cadastralNumber,
	} = defaultValues || {};

	React.useEffect(() => {
		if (!pledgeAddressEgrn) return;
		setAddressEgrn(true);
	}, [pledgeAddressEgrn]);

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			isDisabled: isFieldsDisabled,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'select',
						fieldName: `${arrayName}[${number}].pledge`,
						placeholder: 'Предмет залога',
						isRequired: true,
						errorMessage: 'Выберите предмет залога',
						isLoading: loadingPledge,
						defaultValue: pledge,
						items: selectPledge,
						grid: 3,
					},
				},
				{
					field: {
						fieldType: 'daData',
						fieldName: `${arrayName}[${number}].pledgeAddress`,
						daDataType: 'address',
						placeholder: 'Адрес приобретаемого / закладываемого объекта залога',
						isRequired: true,
						validationType: isFlat ? 'addressWithFlat' : undefined,
						defaultValue: pledgeAddress,
						errorMessage: 'Введите адрес объекта залога',
						grid: 9,
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].cadastralNumber`,
						placeholder: 'Кадастровый номер',
						validationType: 'cadastralNumber',
						defaultValue: cadastralNumber,
					},
				},
				{
					field: {
						fieldType: 'element',
						fieldName: `${arrayName}[${number}].showIsErgn`,
						element: (
							<Button appearance='link' onClick={(): void => setAddressEgrn(state => !state)}>
								Ввести адрес как в ЕГРН
							</Button>
						),
					},
				},
				{
					isHidden: !stateAddressEgrn,
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].pledgeAddressEgrn`,
						placeholder: 'Ввод адреса из ЕГРН',
						defaultValue: pledgeAddressEgrn,
					},
				},
				{
					field: {
						fieldType: 'range',
						fieldName: `${arrayName}[${number}].pledgeShare`,
						placeholder: 'Доля приобретаемого / закладываемого объекта залога',
						isRequired: true,
						min: 0,
						max: 100,
						step: 1,
						defaultValue: pledgeShare,
						errorMessage: 'Выберите долю',
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].area`,
						defaultValue: area || '',
						digitsAfterDot: 2,
						grid: 6,
						placeholder: 'Общая площадь, м2',
						isRequired: true,
						validationType: 'minMax',
						maxValue: 1000,
						errorMessage: 'Введите площадь',
					},
				},
				{
					isHidden: !ROOM_REQUIRED.includes(String(pledgeIsn)),
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].roomsCount`,
						placeholder: 'Количество комнат',
						grid: 6,
						isRequired: true,
						validationType: 'minMax',
						minValue: 1,
						defaultValue: roomsCount,
					},
				},
				{
					isHidden: !FLOOR_REQUIRED.includes(String(pledgeIsn)) || !isFull,
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].floor`,
						defaultValue: floor,
						isRequired: true,
						grid: 6,
						errorMessage: 'Введите этаж',
						validationType: 'minMax',
						minValue: 1,
						maxValue: floorCountValue,
						placeholder: 'Этаж',
						minMaxMessage: 'Этаж квартиры не должен превышать общую этажность строения'
					},
				},
				{
					isHidden: FLOOR_COUNT_HIDDEN.includes(String(pledgeIsn)) || !isFull,
					field: {
						fieldType: 'number',
						isRequired: true,
						grid: 6,
						errorMessage: 'Введите этажность строения',
						fieldName: `${arrayName}[${number}].floorCount`,
						defaultValue: floorCount,
						placeholder: 'Этажность строения',
					},
				},
				{
					field: {
						fieldType: 'inputMask',
						fieldName: `${arrayName}[${number}].yearOfBuild`,
						placeholder: 'Год постройки / год приобретения',
						mask: '9999',
						grid: 6,
						validationType: 'minMax',
						maxValue: Number(dayjs().format('YYYY')) + 1,
						defaultValue: yearOfBuild,
						isRequired: true,
						errorMessage: 'Введите год',
					},
				},
				{
					field: {
						isHidden: !isFull,
						fieldType: 'inputMask',
						grid: 6,
						fieldName: `${arrayName}[${number}].yearOfCapitalRepair`,
						placeholder: 'Год капитального ремонта',
						defaultValue: yearOfCapitalRepair,
						mask: '9999',
					},
				},
				{
					isHidden: !isFull || FLOOR_COUNT_HIDDEN.includes(String(pledgeIsn)),
					field: {
						fieldType: 'select',
						fieldName: `${arrayName}[${number}].wallMaterial`,
						isRequired: true,
						grid: 6,
						items: selectWallMaterial,
						isLoading: loadingWallMaterial,
						defaultValue: wallMaterial,
						placeholder: 'Материал стен',
					},
				},
				{
					isHidden: !isFull || FLOOR_COUNT_HIDDEN.includes(String(pledgeIsn)),
					field: {
						fieldType: 'select',
						fieldName: `${arrayName}[${number}].floorMaterial`,
						isRequired: true,
						grid: 6,
						items: selectWallMaterial,
						isLoading: loadingWallMaterial,
						defaultValue: floorMaterial,
						placeholder: 'Материал межэтажных перекрытий',
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].subjectCost`,
						digitsAfterDot: 2,
						grid: 6,
						defaultValue: subjectCost || '',
						placeholder: 'Рыночная стоимость объекта залога (согласно отчету об оценке)',
						isRequired: true,
						errorMessage: 'Введите стоимость',
						validationType: 'minMax',
						maxValue: 1000000000,
					},
				},
				{
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].subjectCostByContract`,
						defaultValue: subjectCostByContract || '',
						grid: 6,
						digitsAfterDot: 2,
						placeholder: 'Стоимость объекта залога по договору купли-продажи',
					},
				},
				{
					isHidden: !isFull,
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].agency`,
						defaultValue: agency,
						placeholder: 'Агентство недвижимости, которое готовит сделку купли-продажи, ФИО и телефон агента',
						grid: 6,
					},
				},
				{
					isHidden: !isFull,
					field: {
						fieldType: 'input',
						defaultValue: nameOfAppraiser,
						fieldName: `${arrayName}[${number}].nameOfAppraiser`,
						placeholder: 'Наименование назависимого оценщика',
						grid: 6,
					},
				},


				{
					isHidden: !isFull,
					label: 'Сигнализация',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].signaling`,
						items: RADIO_BOOLEAN,
						defaultValue: signaling,
					},
				},
				{
					isHidden: !isFull || !isSignaling,
					field: {
						fieldType: 'input',
						isRequired: true,
						fieldName: `${arrayName}[${number}].signalingInput`,
						defaultValue: signalingInput,
						placeholder: 'Сигнализация',
					},
				},
				{
					isHidden: !isFull,
					label: 'Наличие источников открытого огня (газовая плита / колонка /другое)',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].openFire`,
						items: RADIO_BOOLEAN,
						defaultValue: openFire,
					},
				},
				{
					isHidden: !isFull || !isOpenFire,
					field: {
						fieldType: 'input',
						isRequired: true,
						fieldName: `${arrayName}[${number}].openFireInput`,
						defaultValue: openFireInput,
						placeholder: 'Источники открытого огня',
					},
				},
				{
					isHidden: !isFull,
					label: 'Наличие дополнительных факторов риска (бассейн, сауна, другое)',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].additionalRisks`,
						items: RADIO_BOOLEAN,
						defaultValue: additionalRisks,
					},
				},
				{
					isHidden: !isFull || !isAdditionalRisks,
					field: {
						fieldType: 'input',
						isRequired: true,
						fieldName: `${arrayName}[${number}].additionalRisksInput`,
						defaultValue: additionalRisksInput,
						placeholder: 'Дополнительные риски',
					},
				},

				{
					isHidden: !isFull,
					label: 'Объект незавершенного строительства',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].objectOfUnfinishedConstruction`,
						items: RADIO_BOOLEAN,
						defaultValue: objectOfUnfinishedConstruction,
					},
				},
				{
					isHidden: !isFull,
					label:
						'Планируете ли Вы проведение ремонтных работ, связанных с изменениями в конструктивных элементах недвижимого имущества (перенос стен, создание перегородок и проемов в стенах, замена труб)? ',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].repairPlanning`,
						items: RADIO_BOOLEAN,
						defaultValue: repairPlanning,
					},
				},
				{
					isHidden: !isPlanningRepair || !isFull,
					field: {
						fieldType: 'date',
						grid: 6,
						fieldName: `${arrayName}[${number}].repairIntervalStart`,
						defaultValue: repairIntervalStart,
						placeholder: 'Дата начала ремонта',
					},
				},
				{
					isHidden: !isPlanningRepair || !isFull,
					field: {
						fieldType: 'date',
						grid: 6,
						fieldName: `${arrayName}[${number}].repairIntervalEnd`,
						defaultValue: repairIntervalEnd,
						placeholder: 'Дата окончания ремонта',
					},
				},
				{
					isHidden: !isPlanningRepair || !isFull,
					field: {
						fieldType: 'number',
						fieldName: `${arrayName}[${number}].riskCoefficient`,
						defaultValue: riskCoefficient,
						placeholder: 'Коэффициент за повышенный риск объекта страхования',
					},
				},
				{
					isHidden: !isFull,
					label: 'Подвальное или чердачное помещение?',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].basementOrAttic`,
						items: RADIO_BOOLEAN,
						defaultValue: basementOrAttic,
					},
				},


				{
					isHidden: !isFull,
					label: 'Внутрення отделка и инженерное оборудование',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].interiorDecoration`,
						defaultValue: interiorDecoration,
						items: RADIO_BOOLEAN,
					},
				},
				{
					isHidden: !isFull,
					label: 'Вы планируете или проводите перепланировку / переоборудование? Укажите сроки перепланировки / переоборудования',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].replanPlanning`,
						items: RADIO_BOOLEAN,
						defaultValue: replanPlanning,
					},
				},
				{
					isHidden: !isPlanningReplan || !isFull,
					field: {
						fieldType: 'date',
						grid: 6,
						fieldName: `${arrayName}[${number}].replanIntervalStart`,
						placeholder: 'Дана начала перепланировки / переоборудования',
						defaultValue: replanIntervalStart,
					},
				},
				{
					isHidden: !isPlanningReplan || !isFull,
					field: {
						fieldType: 'date',
						grid: 6,
						fieldName: `${arrayName}[${number}].replanIntervalEnd`,
						placeholder: 'Дана окончания перепланировки / переоборудования',
						defaultValue: replanIntervalEnd,
					},
				},
				{
					isHidden: !isFull,
					label: 'Будет ли недвижимое имущество сдаваться в аренду третьим лицам?',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].rent`,
						items: RADIO_BOOLEAN,
						defaultValue: rent,
					},
				},
				{
					isHidden: !isFull,
					label: 'Известны ли Вам физические повреждения объекта залога за последние 2 года, сумма ущерба которых превышала 200 000 ₽? ',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].damage`,
						items: RADIO_BOOLEAN,
						defaultValue: damage,
					},
				},
			],
		}),
		[
			additionalRisks,
			additionalRisksInput,
			agency,
			area,
			arrayName,
			basementOrAttic,
			cadastralNumber,
			damage,
			floor,
			floorCount,
			floorMaterial,
			formHook,
			interiorDecoration,
			isAdditionalRisks,
			isFlat,
			isFull,
			isOpenFire,
			isPlanningRepair,
			isPlanningReplan,
			isSignaling,
			loadingPledge,
			loadingWallMaterial,
			nameOfAppraiser,
			number,
			objectOfUnfinishedConstruction,
			openFire,
			openFireInput,
			pledge,
			pledgeAddress,
			pledgeAddressEgrn,
			pledgeIsn,
			pledgeShare,
			rent,
			repairIntervalEnd,
			repairIntervalStart,
			repairPlanning,
			replanIntervalEnd,
			replanIntervalStart,
			replanPlanning,
			riskCoefficient,
			roomsCount,
			selectPledge,
			selectWallMaterial,
			signaling,
			signalingInput,
			stateAddressEgrn,
			subjectCost,
			subjectCostByContract,
			wallMaterial,
			yearOfBuild,
			yearOfCapitalRepair,
		]
	);

	const value: PropertyContext = React.useMemo(
		() => ({
			form,
		}),
		[form]
	);

	return <PropertyContext.Provider value={value}>{children}</PropertyContext.Provider>;
};

export default React.memo(PropertyContextProvider);
