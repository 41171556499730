import React, {createContext, useContext, useEffect} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from '../../../../../libs/api/queries';
import {DashboardInfoQuery, DashboardInfoQueryVariables, useMockMutation} from '../../../../../libs/api/graphqlTypes';
import { useUserRights } from '../../../common/hooks/useUserRights';
import { useGlobalContext } from '../../../../../apps/main/ui/GlobalContext';
import { IDropdownItem } from '../../../../../libs/uiKit/dropdownMenu/types';
import {checkUiVersion} from "../../../../../libs/utils/checkUiVersion";

type DashboardStatus = {
	status: string;
	count: number;
};

type DashboardCard = {
	title: string;
	clickHandler: () => void;
	linkText: string;
	isDisabled: boolean;
	items: DashboardStatus[];
};

type DashboardContext = {
	cards: DashboardCard[];
	isCanCreateAgreement: boolean;
	dropDownItems: IDropdownItem[];
	// precalc
	isOpenSkipPrecalculation: boolean;
	closeSkipPrecalculationonHandler: () => void;
	skipPrecalculationCallback: (id: string) => void;
	banksOk: boolean;
};

const DashboardContext = createContext<DashboardContext>({} as DashboardContext);

export const useDashboardContext = (): DashboardContext => useContext(DashboardContext);

const DashboardContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const {
		routes: { agreementsLog, contractsLog, error500, precalculation, createAgreementStep },
		mockData,
		user: { id: userId, banksOk }
	} = useGlobalContext();
	const { rightsHandler } = useUserRights();
	const navigate = useNavigate();
	const [stateSkipPrecalculationModal, setSkipPrecalculationModal] = React.useState<boolean>(false);

	const { data: dataDashboard } = useQuery<DashboardInfoQuery, DashboardInfoQueryVariables>(DASHBOARD, { onError: error => error500(error) });
	const [mock, { loading: loadingMock }] = useMockMutation();

	useEffect(() => {
		checkUiVersion();
	}, []);

	const createWithMock = (key: string) => {
		mock({ variables: { key } }).then(({ data }) => {
			const id = data?.mock;
			navigate(createAgreementStep(String(id), 'contragentStep'));
		}).catch(error => console.error(error));
	}

	const dropDownItems: IDropdownItem[] = React.useMemo(
		() => [
			{
				label: 'С предварительным расчетом',
				onClick: () => navigate(precalculation()),
			},
			{
				label: 'Без предварительного расчета',
				onClick: () => setSkipPrecalculationModal(true),
			},
			...mockData ? [
				{
					label: 'Mock: Сбербанк Жизнь + Имущество',
					onClick: () => createWithMock("sber_lp"),
				},
			] : []
		],
		[navigate, precalculation]
	);

	const value: DashboardContext = React.useMemo(
		() => ({
			cards: [
				{
					title: 'Договоры',
					linkText: 'Журнал договоров',
					clickHandler: () => navigate(contractsLog()),
					isDisabled: !rightsHandler('agreementsLogPage'),
					items: (dataDashboard?.dashboard?.contracts || []).map(status => ({ status: status?.status || 'unknown', count: status?.count || 0 })),
				},
				{
					title: 'Заявки',
					linkText: 'Журнал заявок',
					clickHandler: () => navigate(agreementsLog()),
					isDisabled: !rightsHandler('applicationsLogPage'),
					items: (dataDashboard?.dashboard?.agreements || []).map(status => ({ status: status?.status || 'unknown', count: status?.count || 0 })),
				},
			],
			isCanCreateAgreement: rightsHandler('precalculationPage') && banksOk,
			dropDownItems,
			isOpenSkipPrecalculation: stateSkipPrecalculationModal,
			closeSkipPrecalculationonHandler: () => setSkipPrecalculationModal(false),
			skipPrecalculationCallback: id => navigate(createAgreementStep(id, 'contragentStep')),
			banksOk : userId ? banksOk : true,
		}),
		[
			rightsHandler,
			dataDashboard?.dashboard?.contracts,
			dataDashboard?.dashboard?.agreements,
			dropDownItems,
			stateSkipPrecalculationModal,
			navigate,
			contractsLog,
			agreementsLog,
			createAgreementStep,
		]
	);

	return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

export default React.memo(DashboardContextProvider);
