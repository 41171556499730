import React from 'react';
import { useProfileContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import { Heading2, Heading3 } from '../../../../libs/uiKit/globalStyles';
import { StyledProfileCard, StyledProfileFieldItem, StyledProfileForm, StyledProfilePasswordWrapper, StyledProfileWrapper } from './styles';
import { FieldsBuilder } from '../../../../libs/uiKit/fieldsBuilder';
import Button from '../../../../libs/uiKit/button';
import { Text4 } from '../../../../libs/uiKit/globalStyledComponents';
import ChangePasswordIgniter from '../widgets/changePassword';

const ProfileUi: React.FC = () => {
	const { username, form, onFormSubmit, isShowSave, isLoading, isShowChangePassword, changePasswordIsOpen, switchChangePasswordHandler } = useProfileContext();

	return (
		<MainLayoutIgniter>
			<form onSubmit={onFormSubmit}>
				<StyledProfileWrapper>
					<StyledProfileCard>
						<Heading2>{username}</Heading2>
						<StyledProfileForm>
							<Heading3>Профиль</Heading3>
							<Text4>ничего нет</Text4>
							<Heading3>Реквизиты</Heading3>
							<Text4>ничего нет</Text4>

							{isShowChangePassword && (
								<StyledProfileFieldItem>
									<Text4>Пароль</Text4>
									<StyledProfilePasswordWrapper>
										<Text4>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</Text4>
										<Button appearance='link' onClick={() => switchChangePasswordHandler(true)}>
											Изменить
										</Button>
									</StyledProfilePasswordWrapper>
								</StyledProfileFieldItem>
							)}

							<Heading3>Контакты</Heading3>
							<FieldsBuilder {...form} />
						</StyledProfileForm>
					</StyledProfileCard>
				</StyledProfileWrapper>
			</form>
			<ChangePasswordIgniter onClose={() => switchChangePasswordHandler(false)} isOpen={changePasswordIsOpen} />
		</MainLayoutIgniter>
	);
};

export default React.memo(ProfileUi);

// 			{profile.map(({ label, value }, index) => {
// 							if (!value) return null;
// 							return (
// 								<StyledProfileFieldItem key={`profile-field-${index}`}>
// 									<TextButton>{label}</TextButton>
// 									<TextButton>{value}</TextButton>
// 								</StyledProfileFieldItem>
// 							);
// 						})}
