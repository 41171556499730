import React from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { ROLES_TABS, useRolesContext } from './context';
import MainLayoutIgniter from '../../common/mainLayout';
import { StyledButtonsGroupAutoSize, StyledFullSizeOnMobileButton, StyledRolesHeader, StyledRolesTabWrapper, StyledRolesWrapper } from './styles';
import Tabs from '../../../../libs/uiKit/tabs';
import { Heading2 } from '../../../../libs/uiKit/globalStyledComponents';
import Button from '../../../../libs/uiKit/button';
import FlyButtons from '../../../../libs/uiKit/flyButtons';
import RolesTabIgniter from '../widgets/rolesTab';
import GroupTabIgniter from '../widgets/groupTab';
import CreateUserIgniter from '../widgets/createUser';
import CreateRoleIgniter from '../widgets/createRole';
import CreateGroupIgniter from '../widgets/createGroup';
import DeleteRoleIgniter from '../widgets/deleteRole';
import DeleteUserIgniter from '../widgets/deleteUser';
import DeleteGroupIgniter from '../widgets/deleteGroup';
import {useGlobalContext} from "../../../../apps/main/ui/GlobalContext";

const RolesUi: React.FC = () => {
	const {
		activeTab,
		changeTabHandler,
		isShowRoles,
		isShowGroups,

		openCreateUserHandler,
		openCreateGroupHandler,
		openCreateRoleHandler,
		createUserModalData,
		createGroupModalData,
		isOpenCreateRoleModal,
		deleteUserId,
		deleteGroupId,
		deleteRoleId,
		closeCreateUserModal,
		closeCreateGroupModal,
		closeCreateRoleModal,
		closeDeleteUserModal,
		closeDeleteGroupModal,
		closeDeleteRoleModal,
	} = useRolesContext();

	const windowWidth = useWindowWidth({ wait: 300 });

	const {
		user: { isSuper, isAdmin },
	} = useGlobalContext();

	return (
		<MainLayoutIgniter>
			<StyledRolesWrapper>
				<StyledRolesHeader>
					<Heading2>Роли и группы</Heading2>
					{isShowGroups && (
						<FlyButtons isWhiteBackground={true}>
							<StyledButtonsGroupAutoSize>
								{(isSuper || isAdmin) &&
									<Button onClick={(): void => openCreateUserHandler({ isEdit: false, show: true })} appearance='filled'>
										{windowWidth < 768 ? 'Новый пользователь' : 'Создать пользователя'}
									</Button>
								}
								{isSuper &&
									<Button onClick={(): void => openCreateGroupHandler({ isEdit: false })} appearance='filled'>
										{windowWidth < 768 ? 'Новая группа' : 'Создать группу'}
									</Button>
								}
							</StyledButtonsGroupAutoSize>
						</FlyButtons>
					)}
					{isShowRoles && isSuper && (
						<FlyButtons isWhiteBackground={true}>
							<StyledFullSizeOnMobileButton>
								<Button onClick={openCreateRoleHandler} appearance='filled'>
									Создать роль +
								</Button>
							</StyledFullSizeOnMobileButton>
						</FlyButtons>
					)}
				</StyledRolesHeader>

				<StyledRolesTabWrapper>
					<Tabs items={ROLES_TABS} value={activeTab} onChange={changeTabHandler} />
				</StyledRolesTabWrapper>
				{isShowRoles && <RolesTabIgniter />}
				{isShowGroups && <GroupTabIgniter />}
			</StyledRolesWrapper>

			<CreateUserIgniter
				onClose={closeCreateUserModal}
				isOpen={!!createUserModalData?.show}
				header={createUserModalData?.isEdit ? 'Редактирование пользователя' : 'Создать пользователя'}
				{...createUserModalData}
			/>
			<CreateGroupIgniter
				onClose={closeCreateGroupModal}
				isOpen={!!createGroupModalData}
				header={createGroupModalData?.isEdit ? 'Редактировать группу' : 'Создать группу'}
				{...createGroupModalData}
			/>
			<CreateRoleIgniter onClose={closeCreateRoleModal} isOpen={isOpenCreateRoleModal} header='Создать роль' />
			<DeleteUserIgniter id={deleteUserId} isOpen={!!deleteUserId} onClose={closeDeleteUserModal} header='Удалить пользователя' />
			<DeleteGroupIgniter id={deleteGroupId} isOpen={!!deleteGroupId} onClose={closeDeleteGroupModal} header='Удалить группу' />
			<DeleteRoleIgniter id={deleteRoleId} isOpen={!!deleteRoleId} onClose={closeDeleteRoleModal} header='Удалить роль' />
		</MainLayoutIgniter>
	);
};

export default React.memo(RolesUi);
