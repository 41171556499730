import React from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { useContragentsContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import ContragentIgniter from '../../../widgets/contragent';
import { Heading3 } from '../../../../../../libs/uiKit/globalStyledComponents';
import {StyledAnketaInfo, StyledContragent} from './styles';
import Button from "../../../../../../libs/uiKit/button";
import {useContinueWithAnketaMutation} from "../../../../../../libs/api/graphqlTypes";
import useNotification from "../../../../../widgets/notifier/ui/hooks/useNitification";
import {useUserRights} from "../../../../common/hooks/useUserRights";
import {useGlobalContext} from "../../../../../../apps/main/ui/GlobalContext";
import {AGREEMENT_DRAFT} from "../../../../../../libs/api/queries";

const ContragentsUi: React.FC = () => {
	const { isInsurerForm, contragents, anketas } = useContragentsContext();

	const [continueWithAnketa, {loading: continueWithAnketaLoading}] = useContinueWithAnketaMutation();

	const {id} = useParams();

	const {setNotification} = useNotification();

	const navigate = useNavigate();

	const { rightsHandler } = useUserRights();

	const {
		routes: { dashboard, agreementByIdSeller, agreementByIdUnderwriter, createAgreementStep },
	} = useGlobalContext();

	const chooseAnketa = (isn: number) => {
		if(!id) return;
		continueWithAnketa({
			variables: {
				id : parseInt(id, 10),
				anketaIsn: isn
			},
			refetchQueries: [
				{
					query: AGREEMENT_DRAFT,
					variables: {
						id: parseInt(id, 10)
					}
				}
			],
			awaitRefetchQueries: true
		}).then((data) => {
			const agrId = data.data?.continueWithAnketa;
			if(agrId === -1){
				return navigate(createAgreementStep(id, 'bankStep'));
			}
			setNotification({ type: 'success', text: 'Заявка успешно создана' });
			navigate(agrId ? (rightsHandler('underwritingPage') ? agreementByIdUnderwriter(String(agrId)) : agreementByIdSeller(String(agrId))) : dashboard());
		}).catch(error => setNotification({type: "error", text: error}))
	}

	return (
		<>
			{contragents.map(contragent => (
				<StyledContragent key={`contragent-${contragent.number}`}>
					{contragent.number !== 0 && <Heading3>Общие сведения о застрахованном лице</Heading3>}
					<ContragentIgniter {...contragent} />
					{contragent.number === 0 && <FieldsBuilder {...isInsurerForm} />}
					{contragent.number === 0 && anketas?.length > 0 && <>
						<Heading3>Анкеты страхователя</Heading3>
						{anketas.map((anketa : any) => (
							<StyledAnketaInfo>
								<Button
									appearance="link"
									onClick={() => chooseAnketa(anketa?.isn)}
									isLoading={continueWithAnketaLoading}>
									Продолжить с анкетой
								</Button>
								<b>{anketa?.id} от {anketa?.date} ({anketa?.result})</b>
								<div>{anketa?.remark}</div>
							</StyledAnketaInfo>
						))}
					</>}
				</StyledContragent>
			))}
		</>
	);
};

export default React.memo(ContragentsUi);
