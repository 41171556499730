import React, {createContext, useCallback, useContext, useEffect} from 'react';
import { useFormContext } from 'react-hook-form';
import {useSearchParams} from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { IDaDataValue } from '../../../../../../../libs/uiKit/daData/types';
import { ISelectItem } from '../../../../../../../libs/uiKit/select/types';
import { IForm } from '../../../../../../../libs/uiKit/fieldsBuilder/types';
import { getSelectDefaultValue } from '../../../../../../../libs/utils/getSelectdefaultValue';
import useGetDicti from '../../../../../common/hooks/useGetDicti';
import { ContragentFragment, DictKeyEnum, SearchContragentQuery, SearchContragentQueryVariables } from '../../../../../../../libs/api/graphqlTypes';
import { RADIO_BOOLEAN, RADIO_SEX, RUSSIA_COUNTRY_ISN } from '../../../../../../../libs/utils/staticData';
import { SEARCH_CONTRAGENT } from '../../../../../../../libs/api/queries';
import { fieldsValidate } from '../../../../../../../libs/uiKit/fieldsBuilder/utils/fieldValidation';
import { FORMAT_DATE } from '../../../../../../../libs/uiKit/utils';
import useNitification from '../../../../../../widgets/notifier/ui/hooks/useNitification';
import { FillingType } from '../../../anketaFilling/ui/context';
import { JobForm } from '../../../../pages/job/ui/context';
import {useUserRights} from "../../../../../common/hooks/useUserRights";
import {useCreateAgreementContext} from "../../../../ui/context";

type ContragentContext = {
	form: IForm;
	codeResults: string[]|null;
	arrayName: string;
	number: number;
};

export type ContragentContextProps = {
	children?: React.ReactNode;
	isShowShare: boolean;
	isShowSearchByIsn: boolean;
	number: number;
	id?: string;
	defaultValues?: ContragentField;
	arrayName: string;
	searchSuccessCallback: (contragent: ContragentFragment, number: number) => void;
	maxAge?: number;
	contragentDisabled: boolean;
	expressMode?: boolean;

};

export type ContragentId = number | null;

export type ContragentField = {
	fullName?: IDaDataValue | null;
	birthDate?: Date | null;
	addressRadio?: string;
	phoneMobile?: string;
	email?: string;
	gender?: string;
	share?: number;
	addr1?: IDaDataValue | null;
	addr2?: IDaDataValue | null;
	authDocSer?: string;
	authDocNumber?: string;
	authDocDate?: Date | null;
	authDocIssuerCode?: string;
	authDocIssuer?: string;
	country?: ISelectItem | null;
	// anketa
	isn?: ContragentId;
	anketaIsn?: number | null;
	fillingType?: FillingType;
	anketaSigned?: boolean;
	anketaFileId?: string[];
} & JobForm;

const ContragentContext = createContext<ContragentContext>({} as ContragentContext);

export const useContragentContext = (): ContragentContext => useContext(ContragentContext);

const ContragentContextProvider: React.FC<ContragentContextProps> = props => {
	const { setNotification } = useNitification();
	const { children, defaultValues, number, isShowShare, arrayName, searchSuccessCallback, maxAge, contragentDisabled, isShowSearchByIsn, expressMode } = props;

	const { data: selectCountries, loading: loadingCountries } = useGetDicti(DictKeyEnum.Countries);
	const formHook = useFormContext();

	const [searchParams] = useSearchParams();
	const forceAnketa = searchParams.get('forceAnketa');

	const { rightsHandler } = useUserRights();
	const { fullName, addr1, addr2, authDocDate, authDocIssuer, authDocNumber, authDocSer, authDocIssuerCode, birthDate, country, email, gender, addressRadio, share, phoneMobile } =
		defaultValues || {};

	const [stateIsSearch, setIsSearch] = React.useState<boolean>(false);

	const [stateDocCodeResults, setDocCodeResults] = React.useState<string[]|null>(null);

	const [anketaWasSearched, setAnketaWasSearched] = React.useState<boolean>(false);

	const [searchContragent] = useLazyQuery<SearchContragentQuery, SearchContragentQueryVariables>(SEARCH_CONTRAGENT);

	const [addressRadioValue] = formHook.watch([`${arrayName}[${number}].addressRadio`]);
	const isSameAddress: boolean = addressRadioValue === 'true';

	const { agreementId } = useCreateAgreementContext();

	const searchHandler = React.useCallback(
		(searchProps: { currentFullName?: IDaDataValue | null; currentBirthDate?: Date | null; currentPhone?: string }) => {
			const searchData = {
				currentFullName: formHook.getValues(`${arrayName}[${number}].fullName`),
				currentBirthDate: formHook.getValues(`${arrayName}[${number}].birthDate`),
				currentPhone: formHook.getValues(`${arrayName}[${number}].phoneMobile`),
				...searchProps,
			};
			const { currentFullName, currentBirthDate, currentPhone } = searchData;
			if (
				!currentFullName ||
				fieldsValidate({ value: currentFullName, validationType: 'fullName' }) ||
				!currentBirthDate ||
				fieldsValidate({ value: currentBirthDate, validationType: 'date' }) ||
				!currentPhone ||
				fieldsValidate({ value: currentPhone, validationType: 'phone' })
			)
				return;
			setIsSearch(true);
			searchContragent({
				variables: {
					fullname: currentFullName.value,
					birthday: dayjs(currentBirthDate).format(FORMAT_DATE),
					phone: currentPhone,
					id: agreementId,
				},
			})
				.then(({ data }) => {
					// if (!data?.searchContragent) return setNotification({ type: 'warn', text: 'Ничего не найдено' });
					if (!data?.searchContragent) return;
					setNotification({ type: 'success', text: 'Информация найдена' });
					searchSuccessCallback(data.searchContragent, number);
				})
				.catch(error => setNotification({ type: 'error', text: error }))
				.finally(() => setIsSearch(false));
		},
		[formHook, arrayName, number, setNotification, searchContragent, searchSuccessCallback]
	);

	useEffect(() => {
		if(!forceAnketa || anketaWasSearched) return;
		console.log('do force anketa');
		setAnketaWasSearched(true);
		searchHandler({});
	});

	const detectSexByName = useCallback((fullName: IDaDataValue | null | undefined, genderField: string) => {
		const gender = fullName?.data?.gender;
		if(gender){
			formHook.setValue(genderField, gender === 'FEMALE' ? "F" : "M")
		}
	}, [formHook]);

	const searchByIsnHandler = React.useCallback(
		(isn: string) => {
			if (!isn || fieldsValidate({ value: isn, validationType: 'contragentIsn' })) return;
			setIsSearch(true);
			searchContragent({
				variables: {
					id: agreementId,
					isn,
				},
			})
				.then(({ data }) => {
					if (!data?.searchContragent) return setNotification({ type: 'warn', text: 'Ничего не найдено' });
					setNotification({ type: 'success', text: 'Информация найдена' });
					searchSuccessCallback(data.searchContragent, number);
				})
				.catch(error => setNotification({ type: 'error', text: error }))
				.finally(() => setIsSearch(false));
		},
		[formHook, arrayName, number, setNotification, searchContragent, searchSuccessCallback]
	);

	const searchByDocIssuerCode = (code: string) => {
		if (!code || fieldsValidate({ value: code, validationType: 'authDocIssuerCode' })) return;

		const dadataToken = `${process.env.REACT_APP_DADATA_TOKEN}`;
		const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit`;
		const options: RequestInit = {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Token ${dadataToken}`,
			},
			body: JSON.stringify({ query: code }),
		};
		fetch(url, options).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					if(json?.suggestions){
						formHook.setValue(`${arrayName}[${number}].authDocIssuer`, json?.suggestions[0]?.value);
						if(json?.suggestions.length > 1){
							setDocCodeResults(json?.suggestions?.map((item: any) => item?.value));
						} else {
							setDocCodeResults([]);
						}
					} else {
						console.log('searchByDocIssuerCode: no found');
					}
				});
				return null;
			}
		});

		return null;

	};

	const form: IForm = React.useMemo(
		() => ({
			formHook,
			isLoading: stateIsSearch,
			hotReload: true,
			fields: [
				{
					field: {
						fieldType: 'daData',
						fieldName: `${arrayName}[${number}].fullName`,
						placeholder: 'ФИО заемщика',
						daDataType: 'fio',
						isRequired: true,
						isDisabled: contragentDisabled,
						defaultValue: fullName,
						grid: 6,
						onChange: currentValue => {
							detectSexByName(currentValue, `${arrayName}[${number}].gender`);
							searchHandler({ currentFullName: currentValue })
						},
						validationType: 'fullName',
						errorMessage: 'Введите Ф.И.О',
					},
				},
				{
					label: 'Пол заемщика',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].gender`,
						defaultValue: gender,
						grid: 4,
						items: RADIO_SEX,
						isDisabled: contragentDisabled,
						isRequired: true,
					},
				},
				{
					isHidden: !(isShowSearchByIsn && rightsHandler('searchContragentByIsn')),
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].contragentIsn`,
						isDisabled: contragentDisabled,
						grid: 2,
						placeholder: 'Поиск по ISN',
						onChange: ev => searchByIsnHandler(ev.target.value),
						validationType: 'contragentIsn'
					},
				},
				{
					field: {
						fieldType: 'date',
						fieldName: `${arrayName}[${number}].birthDate`,
						isRequired: true,
						isDisabled: contragentDisabled || (expressMode && !!birthDate),
						// pseudoGrid: isShowShare ? undefined : 6,
						grid: 4,
						defaultValue: birthDate,
						onChange: currentValue => searchHandler({ currentBirthDate: currentValue }),
						errorMessage: 'Введите дату рождения',
						placeholder: 'Дата рождения',
						validationType: maxAge ? 'minMaxDate' : undefined,
						minDate: maxAge ? dayjs().add(-maxAge, 'years').toDate() : undefined,
					},
				},
				{
					field: {
						fieldType: 'inputMask',
						fieldName: `${arrayName}[${number}].phoneMobile`,
						isRequired: true,
						isDisabled: contragentDisabled,
						debounceMs: 500,
						grid: 4,
						defaultValue: phoneMobile,
						onChange: ev => searchHandler({ currentPhone: ev.target.value }),
						mask: '+7 (999) 999-99-99',
						validationType: 'phone',
						errorMessage: 'Введите телефон',
						placeholder: 'Телефон',
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].email`,
						isRequired: true,
						isDisabled: contragentDisabled,
						defaultValue: email,
						grid: 4,
						validationType: 'email',
						errorMessage: 'Введите e-mail',
						placeholder: 'E-mail',
					},
				},
				{
					isHidden: !isShowShare,
					field: {
						fieldType: 'range',
						isRequired: true,
						isDisabled: contragentDisabled,
						errorMessage: 'Выберите долю',
						fieldName: `${arrayName}[${number}].share`,
						defaultValue: share,
						grid: 12,
						placeholder: `Доля в общем доходе, %`,
						min: 0,
						max: 100,
						step: 1,
					},
				},
				{
					field: {
						fieldType: 'daData',
						daDataType: 'address',
						fieldName: `${arrayName}[${number}].addr1`,
						defaultValue: addr1,
						placeholder: 'Адрес регистрации',
						isRequired: true,
						isDisabled: contragentDisabled,
						validationType: 'addressWithHouse',
						errorMessage: 'Введите адрес',
					},
				},
				{
					label: 'Адрес регистрации совпадает с фактическим?',
					field: {
						fieldType: 'radio',
						fieldName: `${arrayName}[${number}].addressRadio`,
						isDisabled: contragentDisabled,
						defaultValue: addressRadio,
						items: RADIO_BOOLEAN,
					},
				},
				{
					isHidden: isSameAddress,
					field: {
						fieldType: 'daData',
						daDataType: 'address',
						fieldName: `${arrayName}[${number}].addr2`,
						isRequired: true,
						isDisabled: contragentDisabled,
						defaultValue: addr2,
						validationType: 'addressWithHouse',
						errorMessage: 'Введите адрес',
						placeholder: 'Фактический адрес проживания',
					},
				},
				{
					field: {
						fieldType: 'inputMask',
						fieldName: `${arrayName}[${number}].authDocSer`,
						mask: '9999',
						grid: 3,
						isRequired: true,
						isDisabled: contragentDisabled,
						defaultValue: authDocSer,
						validationType: 'maskFilled',
						errorMessage: 'Введите серию паспорта',
						placeholder: 'Серия паспорта',
					},
				},
				{
					field: {
						fieldType: 'inputMask',
						fieldName: `${arrayName}[${number}].authDocNumber`,
						mask: '999999',
						grid: 3,
						isRequired: true,
						isDisabled: contragentDisabled,
						defaultValue: authDocNumber,
						validationType: 'maskFilled',
						errorMessage: 'Введите номер паспорта',
						placeholder: 'Номер паспорта',
					},
				},
				{
					field: {
						fieldType: 'date',
						fieldName: `${arrayName}[${number}].authDocDate`,
						isRequired: true,
						isDisabled: contragentDisabled,
						grid: 3,
						defaultValue: authDocDate,
						errorMessage: 'Введите дату выдачи паспорта',
						placeholder: 'Дата выдачи паспорта',
						validationType: 'minMaxDate',
						maxDate: dayjs().toDate(),
					},
				},
				{
					field: {
						fieldType: 'inputMask',
						fieldName: `${arrayName}[${number}].authDocIssuerCode`,
						isRequired: false,
						isDisabled: contragentDisabled,
						grid: 3,
						mask: '999-999',
						defaultValue: authDocIssuerCode,
						errorMessage: 'Введите код подразделения',
						placeholder: 'Код подразделения',
						validationType: 'authDocIssuerCode',
						onChange: ev => searchByDocIssuerCode(ev.target.value),
					},
				},
				{
					field: {
						fieldType: 'input',
						fieldName: `${arrayName}[${number}].authDocIssuer`,
						isRequired: true,
						grid: 8,
						isDisabled: contragentDisabled,
						defaultValue: authDocIssuer,
						errorMessage: 'Введите кем выдан паспорт',
						placeholder: 'Кем выдан',
					},
				},
				{
					field: {
						fieldType: 'select',
						fieldName: `${arrayName}[${number}].country`,
						isRequired: true,
						isDisabled: true,
						grid: 4,
						defaultValue: getSelectDefaultValue(country?.value, selectCountries) || getSelectDefaultValue(RUSSIA_COUNTRY_ISN, selectCountries),
						errorMessage: 'Выберите гражданство',
						placeholder: 'Гражданство',
						isLoading: loadingCountries,
						items: selectCountries,
						isSearchAvailable: true,
					},
				},
			],
		}),
		[
			addr1,
			addr2,
			addressRadio,
			arrayName,
			authDocDate,
			authDocIssuer,
			authDocIssuerCode,
			authDocNumber,
			authDocSer,
			birthDate,
			country?.value,
			email,
			formHook,
			fullName,
			gender,
			isSameAddress,
			isShowShare,
			isShowSearchByIsn,
			loadingCountries,
			maxAge,
			number,
			phoneMobile,
			searchHandler,
			selectCountries,
			share,
			stateIsSearch,
		]
	);

	const value: ContragentContext = React.useMemo(
		() => ({
			form,
			codeResults: stateDocCodeResults,
			arrayName,
			number
		}),
		[form]
	);

	return <ContragentContext.Provider value={value}>{children}</ContragentContext.Provider>;
};

export default React.memo(ContragentContextProvider);
