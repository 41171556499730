import React from 'react';
import { useHeaderContext } from './context';
import { StyledHeader, StyledHeaderActions, StyledHeaderNavigation, StyledMobileLogo, StyledNavigationItems, StyledServiceName, StyledUserName } from './styles';
import { BurgerIcon } from '../../../../../../../icons/ui/Burger';
import Button from '../../../../../../../libs/uiKit/button';
import { LogoMobile } from '../../../../../../../icons/logo/LogoMobile';
import { TextButton } from '../../../../../../../libs/uiKit/globalStyledComponents';
import {GET_ADMIN_MESSAGE} from "../../../../../../../libs/api/queries";
import {useQuery} from "@apollo/react-hooks";

const HeaderUi: React.FC = () => {
	const { switchLeftMenuHandler, username, links, isUnderAdmin } = useHeaderContext();

	const { loading: isAdminMessageLoading, data: adminMessage} = useQuery(GET_ADMIN_MESSAGE, {
		pollInterval: 60000
	});

	return (
		<>
			<StyledHeader>
				<StyledHeaderActions>
					<Button appearance='flag' onClick={switchLeftMenuHandler} icon={<BurgerIcon />} />
					<StyledMobileLogo>
						<LogoMobile />
					</StyledMobileLogo>

					<StyledServiceName>
						<TextButton>{adminMessage?.adminMessage ? adminMessage.adminMessage : 'Ипотека'}</TextButton>
					</StyledServiceName>
					{adminMessage?.adminMessage &&
						<StyledServiceName style={{marginRight: '1rem'}}>
							<Button type="button" appearance="transparent" onClick={() => window.location.reload()}>Обновить</Button>
						</StyledServiceName>
					}
				</StyledHeaderActions>

				<StyledHeaderNavigation>
					<StyledUserName>
						{isUnderAdmin ? <TextButton style={{ color: 'red'}}>{username}</TextButton> : <TextButton>{username}</TextButton>}
					</StyledUserName>
					<StyledNavigationItems>
						{links.map(({ icon, clickHandler, isLoading, isHidden }, index) => {
							if (isHidden) return null;
							return <Button appearance='flag' isLoading={isLoading} key={`header-link-${index}`} onClick={clickHandler} icon={icon} />;
						})}
					</StyledNavigationItems>
				</StyledHeaderNavigation>
			</StyledHeader>
		</>
	);
};

export default React.memo(HeaderUi);
