import React from 'react';
import { useContragentContext } from './context';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import {Text4} from "../../../../../../libs/uiKit/globalStyles";
import {StyledDocResult} from "./styles";
import {useFormContext} from "react-hook-form";

const ContragentUi: React.FC = () => {
	const { form, codeResults, arrayName, number } = useContragentContext();

	const formHook = useFormContext();

	const onCodeResultClick = (s: string) => {
		formHook.setValue(`${arrayName}[${number}].authDocIssuer`, s);
	}

	return <>
		<FieldsBuilder {...form} />
		{codeResults && codeResults.length > 0 &&
			<div style={{marginTop: '-20px'}}>
				<Text4>Также найдено по коду подразделения: <br/>
					{codeResults.map((s) => (
						<>
							• <StyledDocResult onClick={() => onCodeResultClick(s)}>{s}</StyledDocResult><br/>
						</>))}
				</Text4>
				<br/>
				<br/>
			</div>
		}
	</>;
};

export default React.memo(ContragentUi);
