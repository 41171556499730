import {BASE_URL} from "./staticData";

export const checkUiVersion = (): void => {

    if(process.env.NODE_ENV === 'development') return;

    const jsFileRegex = /js\/main.(.*).js/gm;
    const cssFileRegex = /css\/main.(.*).css/gm;

    let currentJsKey = '';
    let currentCssKey = '';

    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
        const m = jsFileRegex.exec(scripts[i].src)
        if (m !== null) {
            currentJsKey = m[1];
        }
    }

    const styles = document.getElementsByTagName('link');
    for (let i = 0; i < styles.length; i++) {
        const m = cssFileRegex.exec(styles[i].href)
        if (m !== null) {
            currentCssKey = m[1];
        }
    }

    const currentVersion = currentJsKey + '-' + currentCssKey;

    const staticHtmlUrl = BASE_URL ? BASE_URL + '/index.html' : '/index.html';

    fetch(staticHtmlUrl)
        .then((response) => response.text())
        .then((html) => {
            const m1 = jsFileRegex.exec(html) || ['','']
            const m2 = cssFileRegex.exec(html) || ['',''];
            const serverVersion = m1[1] + '-' + m2[1];
            if(currentVersion !== serverVersion) {
                if(serverVersion.length > 3) {
                    console.log('Check UI version: NEED RELOAD', currentVersion, serverVersion);
                    // get last forced reload time and compare with current time
                    const lastForcedReload = localStorage.getItem('lastForcedReload');
                    const seconds = Math.round((Date.now() - parseInt(lastForcedReload || '0', 10)) / 1000);
                    if(seconds > 300) {
                        console.log('Check UI version: RELOADING');
                        localStorage.setItem('lastForcedReload', Date.now().toString());
                        window.location.reload();
                    } else {
                        console.log('Check UI version: RELOADING SKIPPED', seconds + 's ago');
                    }
                } else {
                    console.log('Check UI version: ERROR index.html')
                }
            } else {
                console.log('Check UI version: OK')
            }
        });
}