import React from 'react';
import { useChangePasswordContext } from './context';
import Button from '../../../../../../libs/uiKit/button';
import { FieldsBuilder } from '../../../../../../libs/uiKit/fieldsBuilder';
import { StyledButtonsGroup } from './styles';
import { Modal } from '../../../../../../libs/uiKit/modal';
import {Text3, Text4} from "../../../../../../libs/uiKit/globalStyles";

const ChangePasswordUi: React.FC = () => {
	const { form, onFormSubmit, isLoading, isOpen, onClose } = useChangePasswordContext();

	return (
		<Modal onClose={onClose} isOpen={isOpen} header='Изменение пароля'>
			<form onSubmit={onFormSubmit}>
				<FieldsBuilder {...form} />
				<div style={{marginTop: "-2rem", marginBottom: "1rem"}}>
					<Text4 style={{color: "gray"}}>Пароль должен состоять минимум из 8 символов, включать строчные и заглавные буквы, а так же спецсимволы</Text4>
				</div>

				<StyledButtonsGroup>
					<Button type='submit' appearance='filled' isLoading={isLoading}>
						Подтвердить
					</Button>
					<Button onClick={onClose} appearance='transparent'>
						Отмена
					</Button>
				</StyledButtonsGroup>
			</form>
		</Modal>
	);
};

export default React.memo(ChangePasswordUi);
